import React, { ComponentProps } from 'react';

import CountryCode from '@root/interfaces/CountryCode';

import { CarrierInfo, RecipientInfo } from '@components/common/BrListItem';

import BrCardFunctionality from '@components/common/BrCardFunctionality';

import BrRecipientNumberForm from '@components/common/BrOfferCard/BrRecipientNumberForm';

interface Props {
  countryCode: CountryCode;
  recipientCfg: {
    imageUrl?: string;
    middleText?: string;
    badgeText?: string;
    onEdit?(): void;
  };
  carrierCfg?: {
    code: string;
    name: string;
    onEdit?(): void;
  };
  phoneFormCfg?: {
    title: string;
    onPhoneChange: ComponentProps<typeof BrRecipientNumberForm>['onPhoneChange'];
  };
}

const RecipientCard: React.FC<Props> = (props) => {
  const { countryCode, recipientCfg, carrierCfg, phoneFormCfg } = props;

  const handleRecipientEditClick = () => {
    recipientCfg.onEdit?.();
  };

  const handleCarrierEditClick = () => {
    carrierCfg?.onEdit?.();
  };

  return (
    <div className="space-y-default">
      <div className="space-y-middle">
        <RecipientInfo
          imageUrl={recipientCfg.imageUrl}
          countryCode={countryCode}
          name={recipientCfg.middleText}
          badgeText={recipientCfg.badgeText}
          onClick={handleRecipientEditClick}
        />
        {carrierCfg ? (
          <div className="border-t border-solid border-t-support-colors/lines" />
        ) : null}
        {carrierCfg ? (
          <CarrierInfo
            size="xSmall"
            name={carrierCfg.name}
            carrierCode={carrierCfg.code}
            countryCode={countryCode}
            onClick={handleCarrierEditClick}
          />
        ) : null}
      </div>
      {phoneFormCfg ? (
        <BrCardFunctionality title={phoneFormCfg.title}>
          <BrRecipientNumberForm
            onPhoneChange={phoneFormCfg.onPhoneChange}
            countryCode={countryCode}
          />
        </BrCardFunctionality>
      ) : null}
    </div>
  );
};

export default RecipientCard;
