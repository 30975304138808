import React from 'react';
import { useTranslation } from 'react-i18next';

import ImtuProductClass from '@root/models/ImtuProductClass';

import { TestIdDataAttr } from '@root/interfaces/components/Common';

import BrAlert from '@components/common/BrAlert';

import BrOfferCard from '@components/common/BrOfferCard';
import { imtuProductSubTypeTextMap } from '../../constants';

interface Props extends TestIdDataAttr {
  data: ImtuProductClass;
  isActive?: boolean;
  onClick: (value: string) => void;
  onDetailsClick?(e: React.MouseEvent, value: string): void;
}

const ImtuProductCard: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const { data, onClick } = props;

  const { t } = useTranslation();

  const handleClick = (id: string) => {
    onClick(id);
  };

  return (
    <>
      <BrOfferCard
        id={data.code}
        planTypeText={t(imtuProductSubTypeTextMap[data.subType])}
        promoBadgeText={data.carrierPromo?.title.toLocaleUpperCase()}
        title={data.subType === 'Mobile Top Up' ? data.recipientAmountText : data.name}
        onActionBtnClick={handleClick}
        actionText={data.senderBaseAmountText}
      >
        {data.carrierPromo?.description || data.description ? (
          <>
            {data.carrierPromo?.description ? (
              <BrAlert
                header={t('Promotion details')}
                icon="egift-solid"
                text={data.carrierPromo?.description}
                classType="warning-variant"
                styleType="with-header"
                className="mb-middle !whitespace-normal w-full"
              />
            ) : null}
            {data.description ? (
              <div className="whitespace-break-spaces text-body/callout/default">
                {data.description}
              </div>
            ) : null}
          </>
        ) : null}
      </BrOfferCard>
    </>
  );
};

export default ImtuProductCard;
