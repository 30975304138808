import React from 'react';

import ImtuProductClass from '@root/models/ImtuProductClass';

import ImtuProductCard from '@components/modules/Imtu/components/CardItem/ImtuProductCard';

interface Props {
  data: ImtuProductClass[];
  selectedItemId?: string;
  onItemSelect?(id: string): void;
}

// there must be a better place to use Modal - some kind of ProductsListWithDetails or even ProductList module
const ProductsList: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { data, selectedItemId, onItemSelect } = props;

  const handleProductSelect = (id: string) => {
    onItemSelect?.(id);
  };

  return (
    <>
      {data.map((item) => {
        return (
          <ImtuProductCard
            key={item.code}
            data={item}
            isActive={item.code === selectedItemId}
            onClick={handleProductSelect}
          />
        );
      })}
    </>
  );
};

export default ProductsList;
