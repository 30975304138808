import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterItemCfg } from '@root/interfaces/BrFilter';
import imtuProducts from '@root/models/ImtuProductClass';
import { imtuProductSubTypeTextMap } from '../../constants';

type Filters = 'all' | 'promo' | 'bundle' | 'data' | 'topup';

interface FilterCfgOption extends FilterItemCfg<Filters> {
  filterFn(products: imtuProducts): boolean;
}

const useImtuProductsFilter = () => {
  const { t } = useTranslation();

  const [values, setValues] = useState<Filters[]>(['all']);

  const filtersCfg: FilterCfgOption[] = [
    {
      title: t('All'),
      value: 'all',
      isSingleSelectionOnly: true,
      filterFn: (products) => {
        return true;
      },
    },
    {
      title: t('Promo'),
      value: 'promo',
      filterFn: (products) => {
        return products.carrierPromo?.description != null;
      },
    },
    {
      title: t(imtuProductSubTypeTextMap['Mobile Bundle']),
      value: 'bundle',
      filterFn: (products) => {
        return products.subType === 'Mobile Bundle';
      },
    },
    {
      title: t(imtuProductSubTypeTextMap['Mobile Data']),
      value: 'data',
      filterFn: (products) => {
        return products.subType === 'Mobile Data';
      },
    },
    {
      title: t(imtuProductSubTypeTextMap['Mobile Top Up']),
      value: 'topup',
      filterFn: (products) => {
        return products.subType === 'Mobile Top Up';
      },
    },
  ];

  const handleFilterSelect = (newValues: Filters[]) => {
    setValues(newValues);
  };

  const filterFnsPipe = (data: imtuProducts[]): imtuProducts[] => {
    const fns = filtersCfg
      .filter((cfgItem) => {
        return values.includes(cfgItem.value);
      })
      .map((cfgItem) => {
        return cfgItem.filterFn;
      });

    const result = data.filter((plan) => {
      return fns.some((fn) => {
        return fn(plan);
      });
    });

    return result;
  };

  const filterOptions = filtersCfg.map((filterCfgItem) => {
    return {
      title: filterCfgItem.title,
      value: filterCfgItem.value,
      isSingleSelectionOnly: filterCfgItem.isSingleSelectionOnly,
    };
  });

  return {
    filterOptions,
    filterFnsPipe,
    handleFilterSelect,
  };
};

export default useImtuProductsFilter;
