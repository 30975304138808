import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStore, observer } from 'mobx-react';

import useDidMount from '@root/hooks/useDidMount';

import { showToast } from '@services/toasts';

import { StepModule } from '@root/interfaces/StepModules';

import ImtuProductClass from '@root/models/ImtuProductClass';

import ImtuCarrierClass from '@root/models/ImtuCarrierClass';

import BrCard from '@components/common/BrCard';
import BrFilter from '@components/common/BrFilter';
import BrCardContent from '@components/common/BrCard/BrCardContent';

import defaultCountryImage from '@src/static/assets/images/default-country-img.png';

import Store from './Store';
import ImtuProductsStore from './ImtuProductsStore';

import ProductsList from './components/ProductsList';
import OffersListSkeleton from './components/OffersListSkeleton';

import useImtuProductsFilter from './useImtuProductsFilter';
import { imtuProductSubTypeTextMap } from '../../constants';

interface Props extends StepModule {
  selectedProduct?: ImtuProductClass;
  recipientPhone: string;
  carrier: ImtuCarrierClass;
  onFinish(args: { product: ImtuProductClass }): void;
}

const UI_MESSAGES = {
  PLEASE_SELECT_PRODUCT: 'Please select a plan', // t('Please select a plan')
};

const Plans: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const { selectedProduct, recipientPhone, carrier, onFinish } = props;

  const { t } = useTranslation();

  const store = useLocalStore(
    () =>
      new Store({
        product: selectedProduct,
      }),
  );
  const imtuProductsStore = useLocalStore(() => new ImtuProductsStore());

  const handleFinish = () => {
    if (store.product) {
      onFinish({
        product: store.product,
      });
    } else {
      showToast.warning(UI_MESSAGES.PLEASE_SELECT_PRODUCT);
    }
  };

  useDidMount(async () => {
    await imtuProductsStore.fetchImtuProducts({
      recipientPhone,
      carrierCode: carrier.code,
    });
    if (!selectedProduct) {
      if (imtuProductsStore.products?.length === 1) {
        store.setProduct(imtuProductsStore.products[0]);
        handleFinish();
      }
    }
  });

  const handleProductSelect = (value: string) => {
    const product = imtuProductsStore.products?.find((item) => {
      return item.code === value;
    });
    if (product) {
      store.setProduct(product);
      handleFinish();
    }
  };

  const { filterOptions, filterFnsPipe, handleFilterSelect } = useImtuProductsFilter();

  const filteredImtuProducts = filterFnsPipe(imtuProductsStore.products || []);

  return (
    <>
      {imtuProductsStore.isDataReady && imtuProductsStore.featuredOffer && (
        <div className="pb-default">
          <BrCard
            variant="new-featured"
            featuredText={t('FEATURED')}
            bgUrl={imtuProductsStore.featuredOffer.carrierPromo?.imageUrl}
            fallbackImageUrl={defaultCountryImage}
            badgeCfg={[
              {
                text: imtuProductSubTypeTextMap[imtuProductsStore.featuredOffer.subType],
                classType: 'neutral',
                styleType: 'default',
              },
              {
                text: imtuProductsStore.featuredOffer.carrierPromo?.title.toLocaleUpperCase(),
                classType: 'warning',
                iconLeft: 'egift-solid',
              },
            ]}
          >
            <BrCardContent
              id={imtuProductsStore.featuredOffer.code}
              planTypeText={imtuProductsStore.featuredOffer?.subType}
              title={imtuProductsStore.featuredOffer?.name}
              brAlertCfg={{
                icon: 'egift-solid',
                classType: 'warning',
                styleType: 'with-header',
                className: 'w-full',
                header: t('Promotion details'),
              }}
              brAlertContent={imtuProductsStore.featuredOffer.carrierPromo?.description}
              details={imtuProductsStore.featuredOffer?.description}
              actionText={`${t('Buy')} ${
                imtuProductsStore.featuredOffer.senderBaseAmountText
              }`}
              onActionBtnClick={handleProductSelect}
            />
          </BrCard>
        </div>
      )}

      <h5 className="text-headers/header-6 text-on-colors/on-background py-xsmall">
        {t('Available top ups for {{recipientPhone}}', {
          recipientPhone,
        })}
      </h5>
      <div className="overflow-x-auto br-container-x-gutter py-default !-mb-small">
        <BrFilter
          options={filterOptions}
          defaultValue="all"
          shouldFallbackToDefault
          isMultiSelect
          onItemSelect={handleFilterSelect}
        />
      </div>

      <div className="flex flex-col space-y-middle">
        {imtuProductsStore.isDataReady ? (
          <>
            {filteredImtuProducts?.length ? (
              <ProductsList
                data={filteredImtuProducts}
                selectedItemId={store.product?.code}
                onItemSelect={handleProductSelect}
              />
            ) : (
              <div className="first-letter:uppercase">
                {t('no products available for this carrier')}
              </div>
            )}
          </>
        ) : (
          <OffersListSkeleton />
        )}
      </div>
    </>
  );
};

export default observer(Plans);
